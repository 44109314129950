<template src="./index.html">

</template>

<script>
import * as XLSX from 'xlsx';
import {
  BBreadcrumb, BButton, BFormFile, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import {VueGoodTable} from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import '@core/scss/vue/libs/vue-good-table.scss'
import {mapActions, mapGetters} from "vuex";
import { Workbook } from "@syncfusion/ej2-excel-export";
import { getUser } from '@/auth/utils'
const user = getUser()
export default {
  name: "ImportStudentCreditClass",
  components : {
    BBreadcrumb,
    BButton,
    BFormFile,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    XLSX,
    VueGoodTable,
    ToastificationContent
  },

  props: {
    dataSend: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      table: {
        fields: [
          {field: 'stt', label: 'STT', sortable: false, type: "string",},
          {field: 'code', label: 'Mã sinh viên', sortable: false, type: "string",},
          {field: 'subjectName', label: 'Tên lớp độc lập', sortable: false, type: "string",},
        ],
        items: [],
      },
      tenFile: "",
      dataExcel: [],
      dataExcelHandle: [],
      arrCode: [],
      dataDuplicate: [],
      totalRecords: 0,
      serverParams: {
        page: 1,
        perPage: 10,
      },
      loading: false
    }
  },

  computed : {
    ...mapGetters({
      total: "rooms/total",

    }),
    // calculate data from init data
  },

  watch : {
    // listening change data init

  },
  methods : {
    ...mapActions({
      importStudentCreditClass: "creditClass/importStudentCreditClass",
    }),
    async importExcel(e) {
      const files = e.target.files;
      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if( files[0].name ) {
        this.tenFile = files[0].name
      }
      this.readFile(files);
    },

    readFile(files) {
      const fileReader = new FileReader();
      fileReader.onload = async (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname],{
            header: 0,
            defval: ""
          });
          const excellist = [];
          // Edit data
          if (ws.length > 0) {
            for (var i = 0; i < ws.length; i++) {
              excellist.push(ws[i]);
            }
          } else {
            this.showToast("File không có dữ liệu", 'XCircleIcon' ,"danger", "");
            return ;
          }
          if (
              !excellist[0].hasOwnProperty("MA_SINH_VIEN")
          ) {
            this.showToast("File chưa đúng định dạng", 'XCircleIcon',"danger", "")
            return;
          }

          this.dataExcel = [];
          const tempArr = []
          for (let k = 0; k < excellist.length; k++) {
            let item = excellist[k];
            let obj = {
              stt: k+1,
              code: item.MA_SINH_VIEN,
              creditClassId: this.dataSend.id,
              subjectName: this.dataSend.subjectName,
              classCode: item.MA_LOP_CD,
            };
            this.dataExcel.push(obj);
            this.arrCode.push(item.MA_SINH_VIEN);
            if (!tempArr.includes(item.MA_SINH_VIEN)){
              tempArr.push(item.MA_SINH_VIEN)
              this.dataExcelHandle.push(obj);
            }
          }
          this.table.items = this.dataExcel;
          this.dataDuplicate = this.toFindDuplicates(this.arrCode);
          this.totalRecords = this.dataExcel.length;
          if (this.totalRecords > 10){
            this.table.items = this.dataExcel.slice(0,10);
          }
          // insert ban ghi
        } catch (e) {
          return alert("Read failure!" + e);
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    toFindDuplicates(array){
      return array.filter((item, index) => array.indexOf(item) !== index)
    },
    toRemoveDuplicate(array){
      return [...new Set(array)];
    },
    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },

    async importStudent(){
      let data = this.dataExcelHandle;
      if (this.dataDuplicate.length > 0){
        this.showToast("Vui lòng loại bỏ các dữ liệu bị trùng !", 'XCircleIcon', "danger", "" )
        return
      }

      if(data.length + this.dataSend.currentQty >  this.dataSend.maxQty ){
        this.$swal({
          title: `Sau khi import số lương sinh viên có thể lớn hơn số lượng tối đa lớp độc lập`,
          text: 'Bạn vẫn muốn thực hiện thao tác này!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
          cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            await this.handleImportStudentCreditClass(data)
          }
        });
      }else{
        await this.handleImportStudentCreditClass(data)
      }
    },

    async handleImportStudentCreditClass(data){
      this.loading = true
      let res = await this.importStudentCreditClass(data)
      this.loading = false
      if (res.code == 1){
        this.showToast("Lưu thành công", 'CheckIcon' ,"success", "" )
        this.resetInputFile()
        this.$emit('onSuccessModal', 'modal-import-student')
      }else{
        this.showToast("Có lỗi xảy ra", 'XCircleIcon',"danger", res.message )
      }
    },

    removeDuplicate(){
      this.dataDuplicate = []
      this.table.items = this.dataExcelHandle
      this.totalRecords = this.dataExcelHandle.length
      if (this.totalRecords > 10){
        this.table.items = this.dataExcelHandle.slice(0,10)
      }
    },

    downloadFile(){
      let columns = [
        { index: 1, width: 130 },
        { index: 2, width: 200 }
      ];
      let rows = [];
      let headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: "MA_SINH_VIEN",
            colSpan: 1,
            style: {
              borders: {
                color: "#00000000",
                lineStyle: "thin",
              },
              fontColor: "#ffffff",
              backColor: "#000000"
            },
          },
          {
            index: 2,
            value: "MA_LOP_CO_DINH",
            colSpan: 1,
            style: {
              borders: {
                color: "#00000000",
                lineStyle: "thin",
              },
              fontColor: "#ffffff",
              backColor: "#000000"
            },
          }
        ],
      };

      rows.push(headerRow);
      let worksheets = [{ columns, rows }];
      let workbook = new Workbook({ worksheets }, "xlsx");

      workbook.save("export_lop_doc_lap.xlsx");
    },
    // handle even

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: title,
          icon: icon,
          variant: variant,
          text: text,
        },
      });
    },

    onPageChange(params) {
      let start = params.currentPerPage * (params.currentPage-1);
      let end = this.dataExcel.length > ( params.currentPerPage * params.currentPage ) ? ( params.currentPerPage * params.currentPage ) : this.dataExcel.length
      this.table.items = this.dataExcel.slice(start, end);
    },

    onPerPageChange(params) {
      let start = params.currentPerPage * (params.currentPage-1);
      let end = this.dataExcel.length > ( params.currentPerPage * params.currentPage ) ? ( params.currentPerPage * params.currentPage ) : this.dataExcel.length
      this.table.items = this.dataExcel.slice(start, end);
    },


  }
}
</script>

<style scoped>

</style>
