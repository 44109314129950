<template>
  <b-modal
    id="learningResultModal"
    centered
    :title="`Xem kết quả học tập lớp ${creditClass.subjectName} - ${creditClass.code}`"
    size="xl"
    body-class="position-static"
    no-close-on-backdrop
    @show="onShow"
    @hide="onHide"
  >
    <b-row class="mt-2">
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="dataSources"
          :pagination-options="paginationOptions"
          :line-numbers="true"
        >
          <div slot="table-actions">
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="GridIcon"
                  size="20"
                  class="text-body"
                />
              </template>
              <b-dropdown-item
                v-for="(column, index) in columns"
                :key="`${index}${column.hidden}`"
                @click.native.capture.stop="toggleColumn(index)"
              >
                <b-form-checkbox :checked="!column.hidden">
                  {{ column.label }}
                </b-form-checkbox>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div
            slot="emptystate"
            style="text-align: center; font-weight: bold"
          >
            Không có bản ghi nào !
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'gender'">
              {{ getGender(props.row.gender) }}
            </span>
          </template>
          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Hiển thị 1 đến </span>
                <b-form-select
                  v-model="itemsPerPage"
                  :options="itemsPerPageOptions"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap">của {{ props.total }} bản ghi</span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="itemsPerPage"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                />
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="mr-1"
          @click="exportExcel"
        >
          <span class="text-nowrap text-right">
            <feather-icon icon="DownloadIcon" /> Xuất excel
          </span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="$bvModal.hide('learningResultModal')"
        >
          <span class="text-right">
            <feather-icon icon="XIcon" /> Hủy
          </span>
        </b-button>
      </div>
    </template>
    <b-overlay
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      :show="isLoading"
      no-wrap
    />
  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BCol,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BModal, BDropdownItem, BDropdown, BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { GENDERS } from '@/const/status'
import { getUser } from '@/auth/utils'
import { between, required } from '@core/utils/validations/validations'
import * as XLSX from 'xlsx'

export default {
  name: 'ProcessScore',
  directives: {
    Ripple,
  },
  components: {
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BButton,
    BCol,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BModal,
    VueGoodTable,
  },
  props: {
    creditClass: {
      type: Object,
      default: () => ({
        subjectName: '',
        code: '',
      }),
    },
    updatable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      scoreQuantity: 0,
      originScores: [],
      required,
      between,
      dataTable: [],
      dataSources: [],
    }
  },
  computed: {
    ...mapGetters({
      learningResult: 'creditClass/learningResult',
      dataExport: 'creditClass/dataExport',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    user() {
      return getUser()
    },
    columns() {
      let data = [
        {
          label: 'Mã sinh viên',
          field: 'studentCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã sinh viên',
          },
          sortable: true,
          hidden: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Họ',
          field: 'lastName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập họ',
          },
          sortable: true,
          hidden: false,
          thClass: 'text-center',
        },
        {
          label: 'Tên',
          field: 'firstName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên',
          },
          sortable: false,
          hidden: false,
          thClass: 'text-center',
        },
      ]
      if (this.learningResult.length > 0) {
        const dataSource = this.learningResult[0]
        if (dataSource.scores.length > 0) {
          dataSource.scores.forEach(score => {
            data = [...data, {
              label: score.name,
              field: score.code,
              hidden: false,
              sortable: false,
              thClass: 'text-center',
              tdClass: 'text-center',
            }]
          })
        }
      }
      data = [...data, {
        label: 'Ghi chú',
        field: 'firstName',
        hidden: true,
        sortable: false,
        thClass: 'text-center',
        tdClass: 'text-center',
      }]
      return data
    },
  },
  methods: {
    ...mapMutations({
      setDataSources: 'creditClass/SET_LEARNING_RESULT',
    }),
    ...mapActions({
      getLearningResult: 'creditClass/getLearningResult',
      getDataExport: 'creditClass/downloadExport',
    }),
    async onShow() {
      await this.getDataSourcesFromStore()
    },
    onHide() {
      this.setDataSources([])
      this.originScores = []
    },
    convertData() {
      const data = []
      if (this.learningResult.length === 0) return data
      this.learningResult.forEach(result => {
        const { scores, ...rest } = result
        if (scores.length > 0) {
          this.scoreQuantity = scores.length
          let element = { ...rest }
          scores.forEach(score => {
            const sc = {}
            sc[`id${score.code}`] = score.id
            sc[score.code] = score.numberValue
            element = { ...element, ...sc }
          })
          data.push(element)
        }
      })
      return data
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getLearningResult({ creditClassId: this.creditClass.id })
        this.dataTable = this.convertData()
        this.dataSources = this.convertData()
        this.originScores = this.dataSources.map(elm => ({ ...elm }))
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getGender(id) {
      return GENDERS.find(gender => gender.value === id).label
    },
    toggleColumn(index) {
      this.$set(this.columns[index], 'hidden', !this.columns[index].hidden)
      this.$forceUpdate()
    },
    async exportExcel() {
      await this.getDataExport({ creditClassId: this.creditClass.id })
      const a = document.createElement('a')
      document.body.appendChild(a)
      const blob = new Blob([this.dataExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = `ket_qua_hoc_tap_${this.creditClass.subjectName}_${this.creditClass.code}.xlsx`
      a.click()
      window.URL.revokeObjectURL(url)
    },
  },
}
</script>
