<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row class="mt-2">
              <b-col cols="12">
                <vue-good-table
                  id="plan-credit-class-list"
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  style-class="vgt-table striped bordered"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :select-options="{
                    enabled: true,
                    selectOnCheckboxOnly: true,
                    selectionText: 'lớp được chọn',
                    clearSelectionText: 'Bỏ chọn',
                    defaultChecked: true
                  }"
                  max-height="500px"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-selected-rows-change="selectionChanged"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'copyNum'">
                      <validation-provider
                        #default="{ errors }"
                        rules="required|between:0,10"
                        name="Số lớp"
                      >
                        <b-form-input
                          v-model="props.row.copyNum"
                          :tabindex="props.row.originalIndex + 1"
                          type="number"
                          name="copyNum[]"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </span>
                    <span
                      v-else-if="props.column.field === 'shouldAddPracticeClass'"
                      :class="props.row.shouldAddPracticeClass == 1 ? 'text-danger font-weight-bold' : ''"
                    >
                      {{props.row.shouldAddPracticeClass == 1 ? 'Có' : 'Không'}}
                    </span>
                    <span v-else-if="props.column.field === 'className'">
                      <b-form-checkbox class="text-danger"
                        :checked="!props.row.isEmptyClass"
                        @change="onCheckEmptyClassChange(props.row)"
                      >{{ props.row.className }}
                      </b-form-checkbox>
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    />
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị 1 đến </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap">của {{ mainData.length }} bản ghi</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>

  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BContainer, BFormCheckbox, BFormInput,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'CreditClassBulkSave',
  directives: {
    Ripple,
  },
  components: {
    BFormCheckbox,
    BFormInput,
    ValidationProvider,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {
    dataSend: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 100,
        ...this.dataSend,
      },
      paginationOptions: {
        enabled: false,
      },
      itemsPerPageOptions: [100],
      isCopy: false,
      selectedMainData: [],
    }
  },
  computed: {
    ...mapGetters({
      mainData: 'creditClass/planDataLists',
      totalRows: 'creditClass/planTotalRows',
      resourceName: 'creditClass/resourceName',
    }),
    columns() {
      return [
        {
          label: '#',
          field: 'rowNum',
          width: '30px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Mã lớp độc lập',
          field: 'code',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tên môn học',
          field: 'subjectName',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Khóa học',
          field: 'courseName',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'semesterName',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Copy Sinh viên Lớp ưu tiên',
          field: 'className',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Sĩ số',
          field: 'maxQty',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Số lớp',
          field: 'copyNum',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tạo lớp TH',
          field: 'shouldAddPracticeClass',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
      ]
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getMainData(this.filter)
      let i
      // eslint-disable-next-line no-plusplus
      for (i = 0; i < this.mainData.length; i++) {
        this.$set(this.mainData[i], 'vgtSelected', true)
      }
      this.$emit('syncData', this.mainData)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: 'creditClass/getPlanData',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    selectionChanged(params) {
      this.selectedMainData = params ? params.selectedRows : []
      this.$emit('syncData', this.selectedMainData)
    },
    onCheckEmptyClassChange(data) {
      if (data) {
        data.isEmptyClass = data.isEmptyClass === 1 ? 0 : 1
      }
    },
  },
}
</script>
